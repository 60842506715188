import React from 'react'
import axios from 'axios'
import { navigate } from "gatsby"
import RightLineArrowIcon from '../Icons/RightLineArrowIcon'
import { FormControlLabel } from "../Section"
import FormButton from "../Button/FormButton"
import WindowedSelect from "react-windowed-select";

class GetInTouchContactForm extends React.Component { 
    constructor(props) {
        super(props)
        this.state = {
            source: '',
            page: '',
            form: '',
            first_name: '',            
            last_name: '',
            email: '',
            phone_no: '',
            zipcode: '',
            city: '',
            state: '',
            //catalogRequest: '',
            message: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleStateChange = this.handleStateChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)

    }
    handleChange(event) {
        const {id, value} = event.target
        this.setState({
            [id]: value
        })
    }
    handleStateChange(event) {
        this.setState({
            state: event.value
        })
    }
    handleSubmit(event) {
        event.preventDefault()
        const { location } = this.props
        //const comment = "Message:" + this.state.message + ", Catalog Request: " + this.state.catalogRequest;
        const comment = this.state.message;


        const data = {
            source: location.origin,
            page: location.href,
            form: "NewCustomerContact",
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,            
            phone_no: this.state.phone_no,
            zipcode: this.state.zipcode,
            state: this.state.state,
            city: this.state.city,
            comment: comment,
            api_token: process.env.GATSBY_FORM_CRM_TOKEN,
        }


        if (typeof window !== `undefined`) {
			document.getElementById("getInTouchContactFormBtn").setAttribute("disabled", "");
		}
        
        
        axios({
            method: "post",
            url: "https://crm.senseicrm.com/api/create-lead-wordpress",
            data: data,
            headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN }
        }).then((res) => {
            if (typeof dataLayer !== 'undefined') {                
                window.dataLayer.push({
                    'event': 'form_submission',
                    'formName': 'NewCustomerContact',
                    'enhanced_conversion_data': {
                        'email': this.state.email,
                        'phone_number': this.state.phone_no
                    }
                });
            }
            this.setState({ source: '', page: '', form: '', first_name: '', last_name: '', email: '', phone_no: '', zipcode: '',  state: '', city: '', message: ''});
            navigate("/thank-you-contact-us");
            
        }).catch((error) => {
            alert(error)
        });          
    }
    render() {
        const { states } = this.props
        let options = [];

        for (let i = 0; i < states.length; i += 1) {
            options.push({
              label: states[i].node.name,
              value: states[i].node.name
            });
          }
          const isSSR = typeof window === "undefined"

        return(                           
            <form id="GetInTouchContactform" onSubmit={this.handleSubmit} className="grid">
                <div className="grid-md-6">
                    <div className="form-group-bordered">
                        <input type="text" id="first_name" className="form-control" onChange={this.handleChange} pattern="[A-Za-z\s]+$" required aria-label="first name"/>
                        <FormControlLabel htmlFor="first_name" className={this.state.first_name!==''?"control-label active":"control-label"}>First Name</FormControlLabel>
                    </div>
                </div>
                <div className="grid-md-6">
                    <div className="form-group-bordered">
                        <input type="text" id="last_name" className="form-control" onChange={this.handleChange} pattern="[A-Za-z\s]+$" required aria-label="last name"/>
                        <FormControlLabel htmlFor="last_name" className={this.state.last_name!==''?"control-label active":"control-label"}>Last Name</FormControlLabel>
                    </div>                
                </div>
                <div className="grid-md-6">
                    <div className="form-group-bordered">
                        <input type="email" id="email" className="form-control" onChange={this.handleChange} required aria-label="email"/>
                        <FormControlLabel htmlFor="email" className={this.state.email!==''?"control-label active":"control-label"}>Email Address</FormControlLabel>
                    </div>
                </div>
                <div className="grid-md-6">
                    <div className="form-group-bordered">
                        <input type="tel" id="phone_no" className="form-control" onChange={this.handleChange} pattern="[0-9]{10}" required aria-label="phone no"/>
                        <FormControlLabel htmlFor="phone_no" className={this.state.phone_no!==''?"control-label active":"control-label"}>Phone Number</FormControlLabel>
                    </div>                
                </div>
                <div className="grid-md-6 z-index-10">  
                    <div className="grid">
                        <div className="grid-md-6">
                            <div className="form-group-bordered">
                                <input type="text" id="zipcode" className="form-control" onChange={this.handleChange} pattern="[0-9]+$" required aria-label="zip code"/>
                                <FormControlLabel htmlFor="zipcode" className={this.state.zipcode!==''?"control-label active":"control-label"}>Zip Code</FormControlLabel>
                            </div>                
                        </div> 
                        <div className="grid-md-6">
                            <div className="form-group-bordered window-select">
                                {!isSSR && <WindowedSelect placeholder="Select State" options={options} aria-label="select state" onChange={this.handleStateChange} />}
                            </div>
                        </div>                       
                    </div>                 
                </div>
                <div className="grid-md-6">  
                    <div className="grid">
                        <div className="grid-md-6">
                            <div className="form-group-bordered">
                                <input type="text" id="city" className="form-control" onChange={this.handleChange} required title=" "/>
                                <FormControlLabel className={this.state.city!==''?"control-label active":"control-label"}>City</FormControlLabel>
                            </div>          
                        </div>
                        <div className="grid-md-6">
                            <div className="form-group-bordered">
                                <div className="control-label-static">Catalog Request</div>
                                <div className="radio-inline">
                                    <label className="pmd-radio radio-item">
                                        <input type="radio" id="yes" name="residence" checked value="Yes"  />
                                        <span className="pmd-radio-label" >&nbsp;</span>
                                        <span className="pmd-radio-text">Yes</span>
                                    </label>
                                    <label className="pmd-radio radio-item">
                                        <input type="radio" id="no" name="residence" value="No"  />
                                        <span className="pmd-radio-label">&nbsp;</span>
                                        <span className="pmd-radio-text">No</span>
                                    </label>
                                </div>
                            </div>      
                        </div>
                    </div>
                </div>
                <div className="grid-12">
                    <div className="form-group-bordered">
                        <textarea type="text" id="message" className="form-control" onChange={this.handleChange} required aria-label="message" />
                        <FormControlLabel className={this.state.message!==''?"control-label active":"control-label"}>Type your message here…</FormControlLabel>
                    </div>
                </div>
                <div className="grid-12">
                    <div className="form-action">
                        <FormButton id="getInTouchContactFormBtn" icon={<RightLineArrowIcon />} text="Submit" />
                    </div>
                </div>
            </form>                
	    )
    }
}

export default GetInTouchContactForm